.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid whitesmoke;
}

.header__left {
  display: flex;
  align-items: center;
}

.header__left > img {
  object-fit: contain;
  height: 80px;
  margin-left: 5px;
}

.header__middle {
  display: flex;
  flex: 0.7;
  align-items: center;
  background-color: whitesmoke;
  padding: 10px;
  border-radius: 5px;
}

.header__middle > .MuiSvgIcon-root {
  color: gray;
}

.header__middle > input {
  border: none;
  width: 100%;
  padding: 10px;
  outline-width: 0;
  font-size: medium;
  background-color: transparent;
}

.header__right {
  display: flex;
  padding-right: 20px;
}

.header__rightAvatar {
  cursor: pointer;
}
