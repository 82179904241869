.emailList {
  flex: 1;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.emailList::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.emailList {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.emailList__settings {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  border-bottom: 1px solid whitesmoke;
  background-color: white;
  z-index: 999;
  padding-right: 10px;
}

.emailList__sections {
  position: sticky;
  top: 0;
  display: flex;
  border-bottom: 1px solid whitesmoke;
  background-color: white;
  z-index: 999;
}

.emailList__list {
  padding-bottom: 20%;
}
